export const colorsDark = {
    background: '#eee',
    text: '#333',
    textSecondry: '#004080',
    border: '#ccc',
    title:'#004080',
    more:'#cc6600'
}
// export const colorLigth = {
//     background: '#eee',
//     text: '#333',
//     textSecondry: '#004080',
//     border: '#ccc',
// }
